/*
 *  Web site styles (except visualizations) 
 */

.unbranded {
  header,
  .brand-only,
  .ad-container,
  .admin,
  .primary.segment h2 {
    display: none !important;
  }
}

.lined() {
  background: url("@{version-prefix}/img/patterns/linedpaper.png");
}

.struck() {
  background: url("@{version-prefix}/img/patterns/struckaxiom.png");
}

.graphy() {
  background: url("@{version-prefix}/img/patterns/graphy.png");
}

.noisy() {
  background: url("@{version-prefix}/img/patterns/noisy_grid.png");
}

.triangles() {
  background: url("@{version-prefix}/img/patterns/gplaypattern.png");
}

.wool() {
  background: url("@{version-prefix}/img/patterns/light_wool.png");
}

.fiber() {
  background: url("@{version-prefix}/img/patterns/lil_fiber.png");
}

.circles() {
  background: url("@{version-prefix}/img/patterns/stacked_circles.png");
}

.zebra() {
  background: url("@{version-prefix}/img/patterns/subtle_zebra_3d.png");
}

.bullseyes() {
  background: url("@{version-prefix}/img/patterns/strange_bullseyes.png");
}

.grid() {
  background: url("@{version-prefix}/img/patterns/tiny_grid.png");
}

.noisy() {
  background: url("@{version-prefix}/img/patterns/noisy_grid.png");
}

.subtle() {
  background: url("@{version-prefix}/img/patterns/p2.png");
}

.connected() {
  background: url("@{version-prefix}/img/patterns/connect.png");
}

.diamonds() {
  background: url("@{version-prefix}/img/patterns/diamond_upholstery.png");
}

.climpek() {
  background: url("@{version-prefix}/img/patterns/climpek.png");
}

.points() {
  background: url("@{version-prefix}/img/patterns/back_pattern.png");
}

@page {
  size: A4;
  margin-left: 25mm;
  margin-right: 15mm;
  margin-top: 20mm;
  margin-bottom: 20mm;

  // Not supported by major browsers right now
  // See https://caniuse.com/mdn-css_at-rules_page_page-margin-boxes
  // Titles are added in print server instead
  @bottom-left {
    margin: 10pt 0 30pt 0;
    border-top: 0.25pt solid #666;
    content: "date" string(date);
    font-size: 9pt;
    color: #333;
  }
  @bottom-right {
    content: "Seite " counter(page) " von " counter(pages); // does not work
  }
}

@header-spacing: 2rem;
.small {
  font-size: 90%; //line-height
}

html {
  scroll-behavior: smooth;
}

body {
  // GENERAL
  &.waiting,
  &.waiting * {
    cursor: wait !important;
  }
  background-color: @nd-white;
  font-weight: @page-font-weight;
  a,
  a:hover,
  a:active,
  button,
  button:hover,
  button:active,
  [data-clickable="true"] {
    &,
    * {
      font-weight: @link-font-weight !important;
    }
    sup {
      font-weight: 600 !important;
    }
  }
  a:not(.button) .icon {
    font-size: 115%;
  }
  .tooltip {
    font-size: 100% / 1.07;
    font-family: @page-font;
    .financial();
  }
  i.icon {
    cursor: default;
    font-weight: normal !important;
  }
  option {
    font-family: inherit;
    font-weight: inherit;
  }
  button i.icon,
  a i.icon {
    cursor: pointer;
  }
  [data-show-for]:not([data-show="true"]) {
    display: none;
  }
  .ui.input,
  .ui.menu,
  .ui.pointing.label {
    font-weight: @page-font-weight !important;
    a,
    a:hover,
    a:active {
      &,
      * {
        font-weight: @page-font-weight !important;
      }
    }
  }
  .ui.menu {
    button.item {
      font-weight: @page-font-weight !important;
      cursor: pointer;
    }
  }
  .ui.icon.message {
    align-items: flex-start;
  }
  .ui.secondary.bottom.pointing.menu .item {
    border-top-color: transparent;
    border-top-width: 2px;
    border-top-style: solid;
    border-bottom-style: none;
  }
  .ui.top.attached.pointing.secondary.menu {
    border-top: none !important;
  }
  .ui.form .field > label,
  th,
  .ui.horizontal.divider,
  .ui.definition.table tr td:first-child {
    font-weight: @link-font-weight !important;
  }
  .divider.large {
    margin: 25px 0;
  } // NAV

  .ui.horizontal.divider::after,
  .ui.horizontal.divider::before {
    top: 0 !important; // workaround for vertical alignment of divider. Got broken im semantic 2.6 or so.
  }
}

body > header {
  background: white;
  nav {
    .logo,
    .header-menu-container {
      &:before,
      &:after {
        display: none;
      }
      &:hover {
        background: transparent !important;
      }
    }
    .logo {
      padding: @header-spacing 0 !important;
      .image {
        margin: 0 !important;
        height: 77px;
        width: 234px !important;
        display: block;
        background-image: url("@{version-prefix}/img/headerlogo.png");
        @media only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min--moz-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (min-device-pixel-ratio: 2),
          only screen and (min-resolution: 192dpi),
          only screen and (min-resolution: 2dppx) {
          background-image: url("@{version-prefix}/img/headerlogo@2x.png");
          background-size: 470px 154px;
          background-size: contain;
        }
      }
    }
    .header-menu > .item,
    &.ui.menu .ui.dropdown .menu > .active.item,
    .dropdown .menu > .item {
      font-weight: @page-font-weight !important;
      color: inherit !important;
    }
    .header-menu-container {
      &.right {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      .ui.dropdown .menu {
        > .header,
        > .item {
          text-transform: none !important;
          font-weight: @page-font-weight !important;
          font-size: 90% !important;
        }
      }
    }
    .phone {
      &.dropdown {
        /*
				margin:0 !important;
				 
				.ui.secondary.menu {
					left: auto;
					right: 0;
					> .item {
						font-size: 1.2rem !important;
					}
				}
			*/
        .content.icon {
          padding: 1rem 1.5rem !important;
          margin: 0 auto !important;
          font-size: 3.5rem;
          width: auto;
          height: auto;
          cursor: pointer;
          color: rgba(0, 0, 0, 0.5);
          /*		&:hover {
						background: @nd-white;
					}*/
        }
      }
    }
  }
  nav,
  nav .no-phone .menu {
    border: none !important;
    box-shadow: none !important;
  }
}

body footer {
  > nav {
    justify-content: center;
    .item {
      justify-content: center;
    }
  }
  margin-bottom: 80px;
  @media (max-width: (@largestMobileScreen)) {
    margin-bottom: 160px;
  }
}

body {
  @media (max-width: (@largestSmallMonitor)) {
    .header-menu > .item {
      @tablet-menu-padding-h: 0.5em;
      padding-left: @tablet-menu-padding-h !important;
      padding-right: @tablet-menu-padding-h !important;
    }
  }
  @media (max-width: (@largestMobileScreen)) {
    .ui.container {
      margin-left: 0 !important;
      margin-right: 0 !important;
      .content > .ui.primary.segment {
        margin: 0 !important;
      }
      .content > .ui.segments {
        margin-top: 0 !important;
      }
    }
    main.ui.container {
      margin-top: 0 !important;
    }
    .header.logo {
      padding-left: 1.5rem !important;
    }
    .board {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .content > .ui.message {
      margin-left: 1rem;
      margin-right: 1rem;
      width: auto;
    }
  }
  .no-phone {
    display: none !important;
  }
  @media (min-width: @computerBreakpoint) {
    .phone {
      display: none !important;
    }
    .no-phone {
      display: inherit !important;
    }
  }
}

// SEARCH BOX
@big-padding: 3rem;
body {
  .ui.primary.segment {
    color: white;
    background-color: @nd-blue;
    padding: 2rem !important;
    @media (min-width: @tabletBreakpoint) {
      & {
        padding: @big-padding !important;
      }
    }
  }
  h1.ui.header {
    overflow-x: hidden;
    margin-top: -3px !important;
    padding-top: 3px;
  }
  h2.ui.header.claim {
    text-transform: uppercase;
    text-align: center;
    font-family: @header-font;
    font-style: normal;
    font-size: 1.28em;
    color: @nd-white;
  }

  .ui.dropdown {
    .menu > .divider + .country-group-item {
      border-top: unset;
    }

    .item > span,
    a > span {
      vertical-align: top;
    }

    .country-item {
      display: inline-flex;
      align-items: center;
    }

    .menu > .message {
      font-weight: 300;
    }
  }

  #search {
    width: 100%;
    box-sizing: border-box !important;
    @input-background: rgb(250, 250, 250);
    .search.icon {
      top: -1px;
    }
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      &.last {
        margin-bottom: 0;
      }
      > * {
        margin: 0 15px 0 0 !important;
        &:last-child {
          margin-right: 0px !important;
        }
      }
    }
    .input {
      width: 100%;
      input {
        border-radius: 0 !important;
        font-weight: @page-font-weight !important;
      }
      i.icon {
        cursor: pointer;
      }
    }

    i.info.icon {
      color: rgba(0, 0, 0, 0.4);
    }

    .power-search .field label {
      height: 20.4px;
    }

    .power-search .tips-and-tricks {
      align-self: center;
      line-height: 1em;
      font-size: 90%;
      opacity: 0.6;
      margin-bottom: 14px !important;
      i.icon {
        opacity: 0.8;
        font-size: 110%;
      }
      &:hover {
        opacity: 0.7;
      }
    }

    .universal-search .tips-and-tricks {
      margin-left: 0;
      margin-right: auto;
      align-self: center;

      font-size: 90%;
      color: white;
      opacity: 0.8;
      font-weight: 300 !important;
      i.icon {
        opacity: 0.5;
        font-size: 110%;
      }
      &:hover {
        opacity: 0.9;
        i.icon {
          opacity: 0.55;
        }
      }
    }

    .universal-search .ui.search .label {
      position: absolute;
      right: 0;
      bottom: 0;
      top: 0;
      border-color: transparent !important;
      color: inherit !important;
      .link {
        &:not(.visible) {
          opacity: 0 !important;
          cursor: default;
        }
        transition:
          opacity 0.3s ease,
          background 0.3s ease !important;
        color: inherit !important;
        opacity: 0.5;
        &:hover {
          opacity: 1;
          color: inherit !important;
        }
      }
    }

    .universal-search .country-selection {
      margin-top: 14px;
      margin-bottom: calc(14px - 28px);
      display: flex;
      justify-content: flex-end;

      @media (min-width: @tabletBreakpoint) {
        & {
          margin-bottom: calc(14px - 42px);
        }
      }
      .ui.dropdown {
        min-height: 30px;
        padding: 0;
        display: inline-flex;
        flex-direction: row-reverse;
        align-items: center;
        flex-wrap: wrap;
        i.icon.dropdown {
          order: -1;
          opacity: 0.5;
        }
        .country-item span {
          font-weight: 300 !important;
        }
        &:hover {
          .default.text {
            opacity: 0.9;
          }
          i.icon.dropdown {
            opacity: 0.8;
          }
        }
      }
      .ui.label {
        box-shadow: none;
        border-radius: 0;
        background-color: fade(@nd-grey, 40%);
        color: white;
        cursor: default;
      }
      .default.text {
        line-height: 1;
        font-weight: 300;
        margin: 0 5px 0 0;
        color: white;
        opacity: 0.8;

        & > div {
          display: flex;
          align-items: center;
        }

        .info.icon {
          margin-right: 0.5rem;
          box-shadow: 0 0 0 0.08em @nd-grey inset;
          font-size: 0.65em;
          color: white;
        }
      }
      i.dropdown.icon,
      i.delete.icon {
        cursor: pointer;
      }
    }

    .power-search .ui.dropdown .item > span {
      padding-left: 0.5em;
      margin-left: 16px;
      line-height: 1.2em;
      display: inline-block;
    }

    .power-search .ui.multiple.dropdown:not(.search) {
      .default.text {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }

    .ui.scrolling.dropdown .menu {
      min-width: calc(100% + 2px) !important;
    }

    .universal-search .ui.search .results {
      width: 100%;
      max-width: 700px;
    }

    .ui.search > .results > .message {
      display: none;
    }

    .ui.category.search input {
      border-color: @input-background !important;
    }

    .ui.search {
      > .results {
        &:empty {
          visibility: hidden !important;
        }
      }
      .category > .name {
        font-family: @header-font !important;
        font-weight: 700;
        text-transform: uppercase !important;
      }
      .result {
        .title {
          font-family: @page-font !important;
        }
      }
    }

    i.flag {
      opacity: 0.75;
    }

    .ui.label {
      font-weight: 500;
    }

    .ui.label.dropdown > i.dropdown.icon {
      margin: 0 0 0 0.25em;
    }

    .min-to-max-dash {
      font-size: 20px;
    }

    .segment-code.dropdown {
      flex: 1;
      min-width: 0;

      .label {
        font-weight: 500 !important;
      }

      & > .menu > .item {
        display: table-row;
        & > * {
          display: table-cell;
          padding: 10px 16px;
        }
        & > * + * {
          padding-left: 0;
          width: 100%;
          line-height: 18px;
          .dont-break-out();
        }

        & > .code {
          margin-right: 0.5em;
          vertical-align: middle;

          .label {
            border-radius: 500rem;
            margin: -0.5em -0.75em;
            padding-left: 0.75em !important;
            padding-right: 0.75em !important;
          }
        }
      }
    }

    .search-filter {
      .left.menu .item {
        text-align: right;
      }

      i.link.icon {
        margin-right: 0.5em;
        &:focus {
          opacity: 1;
          outline: none;
        }
      }
    }
    .search-filter + .search-filter {
      & > label {
        display: none;
      }
    }

    .form-actions {
      margin-top: 20px;
      margin-bottom: -1em;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      & > * {
        margin-bottom: 1em !important;
        margin-right: 0.5em !important;
      }

      button[type="submit"] {
        margin-left: auto;
        margin-right: 0 !important;
      }
    }
  }
}

// Swiss flag is squared: Removing whitespace by setting width from 16px to 11px.
i.flag.ch {
  width: 11px;
  margin-left: 2.5px;
  margin-right: calc(2.5px + 0.5em);
}

i.flag.hvn::before {
  height: 12px !important;
  background-position: 0 0 !important;
  background: url("@{version-prefix}/img/icons/dollar.png") no-repeat !important;
}

i.flag.ru {
  display: none !important;
}

i.flag.gg::before {
  background: url("@{version-prefix}/img/icons/gg.png") no-repeat !important;
}

i.flag.je::before {
  background: url("@{version-prefix}/img/icons/je.png") no-repeat !important;
}

body {
  // IN CHECK
  p.strong,
  span.strong,
  strong {
    font-family: @page-font;
    font-weight: @link-font-weight;
    //font-size: 107%;
    a {
      //font-size: 100% / 1.07;
      font-family: @page-font;
      font-weight: @link-font-weight;
    }
  }
  h1.ui.header,
  h2.ui.header,
  h3.ui.header,
  h4.ui.header {
    &.qualified {
      display: flex;
      align-items: center;
    }
    &.with-buttons {
      flex-wrap: wrap;
      > .content {
        flex-shrink: 0;
      }
      > .buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin-left: auto;
        gap: 1rem;

        .button {
          margin: 0;
        }
      }
    }
    &:first-child {
      margin-top: 0px !important;
    }
    > .content {
      flex-grow: 100;
    }
    > .icon {
      padding-top: 0;
      font-size: 1.4em !important;
      vertical-align: top;
    }
    > .icon > i.icon {
      margin-right: 0;
    }
    .sub.header {
      font-size: 1.1rem;
      font-family: @page-font;
      font-weight: @page-font-weight;
      padding: 0em;
      margin-bottom: 0.28em;
      line-height: 1.15em;
      color: rgba(0, 0, 0, 0.6);
      text-transform: none !important;
    }
  }
  h1.ui.header,
  h2.ui.header {
    line-height: 1.15em;
    text-transform: uppercase;
    .content .heading {
      display: block;
    }
  }
  h3.ui.header {
    line-height: 1.15em;
    text-transform: uppercase;
    font-family: @page-font !important;
    font-weight: @page-font-weight !important;
  }
  h4 {
    line-height: 1.15em;
    font-family: @page-font !important;
    font-weight: @link-font-weight !important;
  }
  .ui.message .header {
    font-family: @page-font !important;
    font-weight: @page-font-weight !important;
  }
  // ADD SOME SPACE TO BOTTOM OF CONTENT
  .ui.segments:not(.horizontal) > .segment:last-child {
    padding-bottom: 2rem;
  }
  @media (max-width: (@largestMobileScreen)) {
    .ui.segment {
      // Allow headings to wrap on mobile
      .ui.ribbon.label {
        min-width: 0;
      }
    }
  }
  @media (min-width: @computerBreakpoint) {
    &[data-ads="false"] {
      .ui.ribbon.large.label {
        font-size: 1.1rem;
        margin-bottom: 1rem !important;
        margin-top: 0.5rem;
        &:after {
          border-width: 0 1em 1em 0;
        }
      }
      .ui.segment {
        padding: 1rem @big-padding;
        .ui.ribbon.label {
          left: calc(-@big-padding - 1.2em);
          margin-right: -1.2em;
          padding-left: calc(@big-padding + 1.2em);
          padding-right: 1.2em;
        }
      }
      .ui.info.segment {
        .ui.ribbon.large.label {
          margin-top: 1rem;
        }
        padding: 2rem @big-padding;
      }
    }
  }
  .ui.single.segment {
    min-height: 420px;
  }
  .ui.segment li {
    margin-bottom: 1em;
  }
  .ui.segment {
    clear: both;
  }
  .large-font .ui.segment {
    font-size: 1.1rem;
  }
  .intro {
    h1.ui.header {
      color: @nd-grey !important;
      text-transform: uppercase !important;
      font-size: 3rem !important;
      line-height: 1 !important;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
      display: block;
    }
  }
  @media (min-width: @tabletBreakpoint) {
    .intro {
      h1.ui.header {
        font-size: 4rem !important;
      }
    }
    .ui.ribbon.label.float-left {
      float: left;
    }
  }
  .samples {
    h3.ui.header {
      text-transform: uppercase;
      margin-top: 1.2em !important;
    }
    p {
      line-height: 1.5;
      margin-bottom: 0.5em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .label {
        margin: 0.25rem;
      }
    }
  }

  .extended-heading {
    @flex-hspacing: 1rem;
    @flex-vspacing: 0.5rem;
    margin: -@flex-vspacing -@flex-hspacing;
    > * {
      margin: @flex-vspacing @flex-hspacing !important;
    }
    h1.ui.header {
      padding-top: @flex-vspacing !important;
      overflow-x: hidden;

      .content .heading {
        @media (max-width: @largestMobileScreen) {
          font-size: 1.28rem;
          line-height: 1.15em;
        }
      }
    }

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    .buttons {
      flex-grow: 0;
      display: flex;
    }
    .button:not(:first-child) {
      margin-left: 0.5rem;
    }
    .button {
      min-width: 120px;
      max-width: 180px;
    }
  }

  .ui.modal.document-shop {
    tr {
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      cursor: pointer;

      &.important {
        background: hsl(52, 100%, 86%);
        font-weight: @bold;
      }
      &.disabled {
        &,
        input[type="checkbox"] {
          cursor: not-allowed;
        }
        color: @mutedTextColor;
      }
    }

    input[type="checkbox"] + label {
      box-sizing: border-box;
      margin-top: 2px;
    }

    .price-label {
      font-size: 1.2rem;
      font-weight: @bold;
      color: @mutedTextColor;
      text-align: left;
    }

    .ui.message {
      margin: 0;
    }

    .price-label {
      margin-left: 0.5rem;
    }

    .dataTables_paginate {
      margin-right: 1.25rem;
    }
    .dataTables_wrapper > .grid > .row:first-of-type {
      padding-top: 0;
    }
  }

  .ui.modal.document-shop-preview {
    .ui.table {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .ui.header.document-shop-header {
    position: relative;
    padding: 2rem 2.25rem;
    border-bottom: 0;

    background: url("@{version-prefix}/img/premium/banner.jpg");
    background-repeat: no-repeat;
    background-position: 50% 90%;
    background-size: 100%;

    .icon {
      vertical-align: baseline;
    }
    .sub.header {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .ui.label > a {
    opacity: 0.9;
  }
  .disclaimer .small {
    font-size: 0.9rem;
  }
  figure {
    height: auto;
    width: auto;
    margin: 0;
    img {
      width: 100%;
    }
  }
  .ribbon.label {
    display: inline-flex;
    align-items: center;
    margin-bottom: 0.67rem !important;
    &,
    a {
      font-weight: 300 !important;
      font-family: @page-font !important;
      text-transform: uppercase;
    }
  }
  .feed .ribbon.label {
    margin-top: 0.67rem !important;
  }
  .search-results {
    .feed {
      margin-top: 5px;
    }
    .event {
      &.active,
      &:hover {
        background-color: @nd-grey;
      }
      .label {
        margin-top: 0.25em;
        margin-left: 0.35em;
        margin-bottom: 3px;
      }
    }
    .extra.text {
      margin-top: 0 !important;
    }
    .ui.feed > .event:last-child {
      padding-bottom: 3px;
    }
    .ui.stackable.grid {
      margin-left: -1rem !important;
      margin-right: -1rem !important;

      .map.column {
        z-index: 5;
        display: flex;
        flex-direction: column;
        @media only screen and (min-width: @tabletBreakpoint) {
          order: 1;
        }
        .export-button {
          margin-top: auto;
          align-self: flex-end;
          .ui.button {
            width: 120px;
            margin-top: 1rem;
            margin-right: 0;
          }
        }
      }
    }
  }
  .more-anchor {
    margin-top: 0.9rem;
  }
  .lined {
    .lined() !important;
  }
  .publication-text {
    overflow: auto;
    img {
      display: none;
    }
    margin-bottom: 0.5em;
    a,
    a:hover,
    a:active {
      &,
      * {
        font-weight: 700 !important;
        transition: border-color 0.4s ease-in-out;
      }
    }
    a:hover:not(.download-link),
    a:active:not(.download-link) {
      &,
      * {
        border-bottom: 2px solid @nd-blue;
      }
    }
    font-family: @mono-font;
    @font-size: 1rem;
    @line-height: 1.6;
    font-size: @font-size;
    line-height: @line-height;
    h1,
    h2,
    h3,
    h4,
    address,
    kbd,
    cite {
      font-size: @font-size;
      line-height: @line-height;
      font-weight: normal;
      font-family: @mono-font;
      margin: 0px;
      font-style: normal;
    }
    h1,
    h2 {
      font-weight: bold;
    }
    h1,
    h2,
    h3 {
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
    h1,
    h2,
    h3,
    h4 {
      text-decoration: underline;
    }
    address,
    kbd,
    cite {
      display: inline;
    }
    h3,
    .lead {
      text-decoration: underline;
      font-weight: bold;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
    kbd {
      font-size: @font-size;
    }
    .line {
      min-height: @line-height;
    } //section { margin-top: @line-height; }
    th,
    td {
      vertical-align: top;
    }
    th + th,
    td + td {
      padding-left: 15px;
    }
  }

  .general-information.ui.list {
    margin: 0 0 1em;

    & + .general-information.ui.list {
      margin-top: 2em;
    }

    .item {
      display: flex;
      align-items: center;

      .icon {
        color: @nd-grey;
        font-size: 90%;
      }
      .ui.circular.label {
        flex: 0 0 auto;
        font-weight: @bold;
        border-radius: 500rem;
        margin: -0.5em 0.5em -0.5em -0.5em;
        padding-left: 0.75em !important;
        padding-right: 0.75em !important;
        cursor: default;
      }
      .icon + .content {
        padding-left: 0.25em;
      }
    }

    .former.item {
      &:first-child .content {
        font-size: 1em;
      }
      .content {
        font-size: 90%;
        color: @slightlyMutedTextColor;
        & > a {
          color: @lightTextColor;
          &:hover {
            color: @mutedTextColor;
          }
        }
        &.strong {
          color: @mutedTextColor;
          font-weight: 500;
        }
      }
    }
  }
}

input::-ms-reveal {
  // Remove Edge's password reveal button
  display: none;
}

main {
  position: relative;
  margin-top: @header-spacing;
  margin-bottom: @header-spacing;
  address {
    display: inline;
    font-style: normal;
  }
}

//====================================================
// ADS
.ad-format(@width, @height) {
  &,
  > ins {
    width: @width;
    height: @height;
  }
  > ins {
    display: block;
  }
  padding: 0;
  border: none !important;
}

.def-ad-container-top(@width, @height) {
  main {
    padding-top: @height;
  }
  main > .content {
    margin-top: 2rem;
  }
  .ad-container-top {
    display: block;
    .ad-format(@width, @height);
    position: absolute;
    top: 0;
    left: 0;
  }
  .ad-container-right {
    display: none;
  }
}

.def-ad-container-right(@width) {
  @height: 600px;
  [data-ads="true"] main {
    padding-right: @width;
    > .content {
      margin-right: 27px;
      min-height: @height;
    }
    .ad-container-right {
      display: flex;
      .ad-format(@width, @height);
      margin-top: 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  [data-ads="false"] main {
    padding-right: 0;
    > .content {
      margin-right: 0;
      min-height: @height;
    }
  }
}

.def-ad-container-right2(@width) {
  @height: 600px;
  [data-ads="true"] main {
    .ad-container-right2 {
      border: none;
      padding: 0;
      background: #e6e5ea;
      box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
      .ad-format(@width, @height);
      display: flex !important;
      width: 300px;
      height: 600px;
      position: absolute !important;
      top: 615px;
      right: 0;
      overflow: hidden;
    }
  }
}

.ad-container {
  display: flex;
  justify-content: center;
  align-items: center;
  ins {
    width: 100%;
  }
}

.ad-container-bottom {
  > ins {
    display: block;
  }
  padding: 0;
  border: none !important;
  //height: 100%;
  width: 100%;
  min-height: 100px;
}

.ad-container-top {
  > ins {
    display: block;
  }
  padding: 0;
  border: none !important;
  //height: 100%;
  width: 100%;
  min-height: 100px;
}

@media (max-width: (@largestMobileScreen)) {
  .ad-container-bottom,
  .ad-container-top {
    min-height: 250px;
  }
}

// PHONES
.ad-container-right,
.ad-container-right2 {
  display: none;
}

.ui.ribbon.label:after {
  display: none;
}

// TABLETS
@media (min-width: @tabletBreakpoint) {
  // 768px
  .ui.ribbon.label:after {
    display: block;
  }
  .def-ad-container-right(120px);
  .def-ad-container-right2(120px);
  .ad-container-top-deco,
  .ad-container-bottom-deco,
  .ad-container-top {
    // display: none !important;
  }
  [data-ads="true"] main > .content {
    min-height: 1220px !important;
  }
}

// MEDIUM DESKTOP
@media (min-width: @computerBreakpoint) {
  // 992px
  .def-ad-container-right(160px);
  .def-ad-container-right2(160px);
}

// LARGE DESKTOP
@media (min-width: @largeMonitorBreakpoint) {
  // 1200px
  .def-ad-container-right(300px);
  .def-ad-container-right2(300px);
}

@media (min-width: @tabletBreakpoint) {
  .mobile-only {
    display: none;
  }
}

@media (max-width: @largestTabletScreen) {
  .mobile-hide {
    display: none;
  }
  .person-pub-list {
    overflow: hidden;
  }
}

.drill-downs {
  .secondary.menu {
    margin-top: 0;
    position: relative;
  }
  .tab-content .tab {
    margin-top: 0 !important;
  }
  @media screen {
    .tab-content {
      position: relative;
      height: 100px;
      padding-bottom: 56.25%;
      .ui.tab {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: inherit;
        visibility: hidden;
        &.active {
          visibility: visible;
        }
      }
    }
  }
}

// LARGE DESKTOP
@media (min-width: @largeMonitorBreakpoint) {
  [data-ads="false"] .drill-downs .tab-content {
    padding-bottom: 31.25%;
  }
}

.bar-charts {
  @media screen {
    .tab-content {
      @tab-content-height: 270px;
      .ui.tab {
        position: absolute;
        left: 0;
        height: @tab-content-height;
        display: inherit;
        visibility: hidden;
        &.active {
          visibility: visible;
        }
      }
      .note {
        padding-left: 11px;
      }
      &.has-bar-charts {
        min-height: @tab-content-height;
      }
      .ui.tab + .note {
        padding-top: @tab-content-height - 5px;
      }
    }
  }
  @media (min-width: @tabletBreakpoint) {
    .menu {
      border-top: none !important;
    }
  }
  @media (min-width: @tabletBreakpoint) and (max-width: 1200px) {
    .menu .item {
      @small-padding: 0.5em;
      padding-left: @small-padding !important;
      padding-right: @small-padding !important;
    }
  }
  .tab-content .tab {
    width: 100%;
  }
  .menu {
    border-left-color: transparent !important;
    border-right-color: transparent !important;

    overflow: hidden;
    flex-wrap: wrap;
    min-height: 0;
    height: 37px;
  }
}

@media screen {
  .print-only {
    display: none !important;
  }
}

.markup() {
  [data-clickable="true"] {
    color: @nd-blue;
    cursor: pointer;
  }
}

.print-style() {
  .print-only {
    display: inherit !important;
  }
  font-size: 16px;
  body {
    overflow-x: auto !important;
    height: auto !important;
    background-color: transparent !important;
    background-image: none !important;
    color: black !important;
  }
  a,
  a:hover,
  a:active,
  button,
  button:hover,
  button:active {
    &,
    * {
      font-weight: @page-font-weight !important;
      color: black !important;
    }
  }
  .screen-only,
  header nav,
  .claim,
  .ad-container,
  .ad-container-top-deco,
  .ad-container-bottom-deco,
  .ui.primary.segment,
  .ui.button,
  .cc_banner-wrapper,
  .tooltip-host,
  [data-layout="sheet"] .icon.download {
    display: none !important;
  }
  button.shake,
  button.print {
    display: none !important;
  }
  main {
    border: none !important;
  }
  h1 {
    .icon {
      display: none !important;
    }
    .content {
      padding-left: 0 !important;
    }
  }
  h2.break-before {
    page-break-before: always;
  }
  .segments {
    border: none !important;
    box-shadow: none !important;
  }
  .segment {
    padding: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;
    border-top: none !important;
    margin-top: 2rem !important;
  }
  h2 + .segment {
    margin-top: 0rem !important;
  }
  .ui.segment.secondary {
    background-color: transparent !important;
    border: none !important;
  }
  .disclaimer {
    padding-top: 0 !important;
    border: none !important;
  }
  .ui.ribbon.label {
    font-size: 115%;
    left: 0 !important;
    margin-left: 0 !important;
    margin-top: 1rem !important;
    padding: 0 !important;
    background-color: transparent !important;
    color: black !important;
    float: none !important;
    text-transform: uppercase;
    &:after {
      display: none;
    }
    margin-bottom: 1.5rem !important;
  }
  h4 {
    color: black !important;
    font-size: 110% !important;
  }
  .board {
    .column {
      width: 100% !important;
    }
  }
  .event .ui.header,
  .pubTable.feed .event,
  .segment.financials,
  .segment.sheets,
  .segment.history,
  .segment.network {
    page-break-inside: avoid;
  }
  [data-layout="barChart"] {
    page-break-inside: auto;
    display: table;

    .chart {
      width: 50%;
      display: inline-block;
      height: 270px;
      page-break-inside: avoid;

      &:nth-child(odd) {
        padding-right: 4px;
      }
      &:nth-child(even) {
        padding-left: 4px;
      }

      svg {
        overflow: visible;
      }
    }
  }
  h2,
  h3,
  h4 {
    page-break-after: avoid;
  }
  [data-layout="pubTable"] {
    // ok
    page-break-inside: auto;
  }
  [data-layout="drillDown"] {
    .box {
      width: 50%;
      page-break-inside: avoid;
      box-shadow: none !important;
      border: 1px solid black !important;
      .value,
      .desc {
        text-shadow: none !important;
      }
    }
  }
  .ui.tab {
    display: inherit;
  }
  .ui.table {
    margin-bottom: 1.5rem !important;
  }
  .printable {
    display: inherit !important;
  }
  //@page {}
  .publication-markup {
    img {
      display: none;
    }
    span {
      font-weight: @link-font-weight;
    }
    .lead {
      text-decoration: underline;
    }
    text-align: justify;
    text-justify: newspaper;
    margin-bottom: 1.5rem;
  }
  .publication-text {
    overflow: visible !important;
  }
  [data-layout="graph"] svg .link .desc {
    //font-weight: @page-font-weight !important;
    opacity: 1 !important;
  }
  [data-layout="financials"] {
    overflow: visible !important;
  }
  [data-layout="sheet"],
  [data-layout="financials"] {
    .source,
    .auditor,
    .note {
      .dont-break-out();
    }
  }
  [data-layout="sheet"] {
    .ui.bizq.table tbody {
      .closed td:first-child:after {
        content: none;
      }
      .expanded td:first-child:after {
        content: none;
      }
    }
  }
  table.styled {
    * {
      box-sizing: border-box;
    }
    &,
    thead,
    tbody {
      display: block;
    }
    tr {
      display: flex;
    }
    th,
    td {
      width: 15%;
      &:first-child {
        width: 25%;
        min-width: auto !important;
      }
      //font-size: 11px;
    }
  }
  .ui.grey.segment,
  .ui.grey.segment:not(.inverted) {
    border-top: none !important;
  }
}

@media print {
  .print-style();
  [data-ads="true"] main {
    display: none !important;
  }
}

.print {
  .print-style();
}

.admin-bar {
  position: fixed;
  right: 15px;
  bottom: 15px;
  padding: 15px;
  background: white;
  border: 1px solid grey;
  z-index: 1000;
  strong {
    display: block;
    margin: 0 0 5px;
  }
  .navigation {
    list-style: none;
    padding: 0;
    margin: 5px 0 0;
    li {
      display: inline-block;
      border-left: 1px solid;
      padding: 0 5px;
      text-transform: capitalize;
      &:first-child {
        border-left: none;
        padding-left: 0;
      }
    }
  }
}

strike {
  position: relative;
  text-decoration: none;
  display: inline-block;
}

strike:after {
  position: absolute;
  width: 100%;
  content: " ";
  left: 0%;
  top: 0;
  height: 70%;
  border-bottom: 0.95em solid #999; //transform: rotateZ(-5deg);
}

.text-style() {
  fill: @fullBlack !important;
  font-weight: @page-font-weight;
}

.line-style() {
  stroke: @fullBlack !important;
  stroke-width: 1px;
  stroke-opacity: 1;
}

.grid-style() {
  stroke-width: 1;
  stroke-opacity: 1;
  stroke: rgba(0, 0, 0, 0.1);
}

[data-layout="barChart"] {
  .tick line {
    .grid-style;
  }
  .y-axis .zero line {
    .line-style;
  }
  .y-axis .domain {
    stroke: @fullBlack;
    stroke-width: 1px;
    stroke-opacity: 1;
  }
  .tick text {
    .text-style();
  }
  .x-axis .tick text {
    transform: translateY(0.3em);
    font-size: 0.8rem !important;
  }
  p {
    .small;
    .markup;
  }
}

[data-layout="history"],
[data-layout="vita"] {
  .tick text {
    .text-style();
    transform: translateY(0.3em);
  }
  .tick line,
  .domain {
    .line-style();
  }
  .grid .tick line,
  line.grid {
    .grid-style() !important;
  }
  .link-layer path[data-highlighted="true"] {
    stroke-width: 3;
  }
  .label-g {
    a {
      text {
        font-weight: 300 !important;
      }
    }
  }
  min-height: 24px;
}

[data-layout="drillDown"] {
  .legends {
    .legend1 {
      font-weight: @page-font-weight !important;
    }
  }
  .box-parent .box .value {
    font-weight: @page-font-weight;
  }
}

.table-styling() {
  line-height: 1em;
  overflow-x: auto;
  th:first-child,
  td:first-child {
    font-weight: 500 !important;
    background: @offWhite !important;
  }
  overflow-x: auto;
  tfoot td:first-child {
    font-weight: 500 !important;
  }
}

[data-layout="sheet"] {
  font-size: 90%;
  .table-styling();
  thead th,
  tbody [data-summary="true"] td:first-child,
  tbody [data-level="0"] td:first-child {
    font-weight: 500 !important;
  }
}

.financial() {
  .source {
    display: block;
  }
}

.ui.ribbon.label {
  .info.icon {
    box-shadow: 0 0 0 0.08em @nd-grey inset;
    font-size: 0.65em;
    color: white;
    margin-left: 1em;
    margin-right: 0;
  }
}

body .financials {
  h2.ui.ribbon.label {
    margin-bottom: 1em !important;
  }
}

[data-layout="financials"] {
  .table-styling();
  .financial();
}

.ui.dimmer.modals {
  background-color: rgba(0, 0, 0, 0.65);
}

.questionnaire {
  .icon {
    font-size: 2.5em !important;
  }
  > .content {
    font-size: 1.1rem !important;
    .ui.list {
      .item {
        margin-bottom: 8px;
      }
      i {
        width: 60px;
        vertical-align: middle;
      }
      .content {
        vertical-align: middle;
      }
    }
  }
  .ui.form {
    .ui.checkbox {
      display: block;
      font-size: 1.1rem;
      margin-bottom: 0.5em;
      label {
        //font-size: 1.1em;
      }
    }
    .field:last-child {
      margin-bottom: 0;
    }
  }
  .sub.header {
    font-family: @page-font;
    font-weight: @page-font-weight !important;
    font-size: 1.2rem !important;
    margin-top: 3px !important;
  }
}

.modal {
  &:not(.legacy) {
    margin: 0 !important;
  }
  .header {
    border-bottom-width: 1.5px !important;
  }
  .content {
    .snippets {
      font-size: 80% !important;
      th {
        padding-right: 0.5em !important;
      }
      b {
        font-weight: @link-font-weight !important;
      }
      .snippet {
        img {
          display: none;
        }
        // overflow-x: hidden;
      }
      .snippet + .snippet {
        margin-top: 1em;
      }
    }
  }
}

// Variant of modal dialog actions where the first child is left aligned
.ui.modal > .actions.left-item {
  @mobileWidth: 550px;

  display: flex;
  @media (min-width: @mobileWidth) {
    > :first-child {
      margin-right: auto;
    }
  }

  @media (max-width: (@mobileWidth - 1px)) {
    flex-direction: column;
    align-items: end;

    > * {
      margin-bottom: 0.5rem;
    }
  }
}

// the following got lost in semantic.min.css...
.ui.modal > .actions {
  background: @offWhite;
  padding: 1rem 1rem;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  text-align: right;
}
.ui.modal .actions > .button {
  margin-left: 0.75em;
}

.pubTable {
  .ui.feed > .event > .content .date {
    color: @fullBlack;
    font-weight: @page-font-weight;
    font-size: 0.9rem;
  }
}

.ui.feed > .event > .label .circular.icon {
  background-color: @nd-white;
}

.ui.inverted.dimmer {
  background-color: transparent;
}

.contact {
  height: 80px;
  .grid {
    display: none;
  }
  [data-field-container] {
    margin-bottom: 0.7em;
    margin-top: 0.2em;
    display: none;
  }
}

.contact2 {
  .people-container {
    display: none;
    max-height: 240px;
    overflow-y: auto;
  }
}

.network {
  width: auto;
  height: 80px;
  position: relative;
  transition: height 0.5s;
  .shake {
    display: none;
  }
  [data-layout="graph"],
  [data-layout="dendrogram"] {
    @padding: 0px;
    position: absolute; //top: @padding;
    top: 1em;
    bottom: @padding;
    left: @padding;
    right: @padding;
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }
  [data-layout="graph"] {
    .shake {
      bottom: 1rem;
      right: 1rem;
      position: absolute;
      display: inherit;
    }
  }
  .link:not(:hover) {
    &[data-highlight="root"],
    &[data-highlight="node"] {
      .desc-bg {
        fill: @nd-light-grey !important;
      }
      .desc {
        font-weight: 500 !important;
      }
    }
  }
  .link:hover {
    .desc {
      font-weight: 500 !important;
    }
  }
}

.tariff-box {
  background-color: white;
  padding: 15px;
  font-size: 15px;
  @media (min-width: @tabletBreakpoint) {
    margin-left: 30px;
  }
  .item {
    margin: 5px 0;
    .icon {
      padding: 0 25px 0 5px;
      color: @nd-green;
      font-size: 18px;
    }
  }
  > .header {
    color: @nd-blue;
  }
  .sub.header {
    font-size: 16px !important;
  }
  .content {
    font-weight: 500;
    a {
      color: black;
    }
  }
  .order,
  p.small,
  .price {
    text-align: center;
  }
  .price {
    margin-bottom: 10px;
    > * {
      display: inline-block;
      margin: 5px 5px;
    }
    .value {
      font-family: @header-font;
      font-weight: @header-font-weight;
      font-size: 40px;
    }
  }
}

.collapse-container {
  // Markdown inside collapse
  p {
    display: inline;
  }
}

.carousel-container {
  position: relative;

  .carousel-content {
    position: relative;
    overflow: hidden;
  }

  .carousel-nav {
    display: flex;
    justify-content: center;

    position: absolute;
    width: 100%;
    bottom: 1rem;
    z-index: 2;
  }

  .carousel-nav-button {
    cursor: pointer;
    background: rgba(255, 255, 255, 0.6);

    width: 16px;
    height: 16px;
    margin: 0 4px;

    border: 0;
    border-radius: 50%;

    &.active {
      background: rgba(255, 255, 255, 0.9);
    }
  }

  .carousel-button {
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-top-width: 20px;
    border-bottom-width: 20px;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    z-index: 2;
    padding: 0;

    &.carousel-button-left {
      left: -12px;
      border-right: 35px solid fade(@nd-blue, 90%);

      &:hover {
        border-right-color: fade(@nd-blue, 75%);
      }
    }

    &.carousel-button-right {
      right: -12px;
      border-left: 35px solid fade(@nd-blue, 90%);

      &:hover {
        border-left-color: fade(@nd-blue, 75%);
      }
    }
  }

  .carousel-page {
    position: absolute;
    width: 100%;
    height: max-content;
    bottom: 0;
    top: 0;

    display: flex;
    flex-direction: column;

    padding: 1.5rem 2.5rem 50px;

    background-color: @nd-white;
    background-size: cover;

    &.active {
      z-index: 1;
    }
  }
}

.journalism-container {
  margin-bottom: 1rem;

  @media (min-width: @computerBreakpoint) {
    display: flex;
    justify-content: space-between;

    .carousel-container {
      width: 68.75%;
      margin-right: 1rem;
    }
  }

  .carousel-content {
    min-height: 600px;
  }

  .journalism-article-header {
    background: rgba(255, 255, 255, 0.55);
    margin: 0;
    padding: 1rem;
    border-bottom: 4px solid @nd-blue;
    border-radius: 8px 8px 0 0;
  }

  .journalism-article {
    position: relative;
    background: rgba(255, 255, 255, 0.9);
    flex-grow: 1;
    padding: 1rem 1rem 3rem;
    border-radius: 0 0 8px 8px;
  }

  .journalism-article-label {
    position: absolute;
    min-width: 180px;
    bottom: 0;
    right: 0;

    border-radius: 8px;
    margin: 2px;
    padding: 1rem;

    background: @nd-blue;
    color: white;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    line-height: 1;
  }

  .journalism-statistics {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: @computerBreakpoint) {
      width: 31.25%;
      min-height: 450px;
    }

    font-family: @header-font;
    font-size: 1.5rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.35em;

    color: white;

    padding: 1.5rem;
    background-color: @nd-blue;
  }

  .journalism-counter {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: @page-font;
    font-size: 2.2rem;
    font-weight: 500;
    color: @nd-dark-blue;

    border-radius: 50%;
    width: 120px;
    height: 120px;

    margin: 2rem auto;
  }
}

.logo-container {
  height: 70px;
  display: flex !important;
  overflow: hidden;
  justify-content: center;
  align-items: center;
}

.logo-nr img {
  max-height: 100%;
  width: auto;
  object-fit: contain;
}

.logo-icij img {
  max-height: 100%;
  width: auto;
  border-right: 6px solid #fff !important;
  display: block;
}

.logo-correl img {
  max-width: 100%;
  margin: auto;
  width: auto;
  height: auto;
}

body .header-box.segments {
  margin-top: 1rem;
  margin-bottom: 1rem;

  & .ui.segment {
    margin-top: 0;
    margin-bottom: 0;
  }
}

body .ui.header-box.segment {
  &.small {
    padding: 1rem !important;
  }

  & .ui.header {
    color: #fff;
  }
}

.signup-welcome {
  & .ui.info.segment {
    margin-bottom: 0;
  }

  & .signup-footer {
    display: block;
    width: 100%;
    height: 191px;

    margin-bottom: 1rem;

    background-image: url(https://blog.northdata.de/hubfs/KFMz6LPw.jpeg);
    background-position-x: 5%;
  }
}

.ui.grid.compact-columns > .column {
  width: 50% !important;
  @media (max-width: (@largestTabletScreen)) {
    width: 100% !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }

  display: flex;
  flex-direction: column;

  &:first-of-type {
    padding-right: 0.1rem;
  }
  &:last-of-type {
    padding-left: 0.1rem;
  }

  & .page {
    display: none;
    height: 100%;
  }

  & .active.page {
    display: flex;
    flex-direction: column;
  }

  & .ui.content.segment {
    height: 100%;
    flex-grow: 1;
    background-color: @nd-white;
    padding: 1rem 1.5rem;

    & .info-hint {
      margin-top: 0.5rem;
      text-align: center;
    }
  }

  & h2 {
    font-size: 1.4rem;
    text-transform: none;
    margin-top: 0;
    clear: both;
  }

  & .info-box:not(:first-child) {
    margin-top: 2rem;
  }
}

.selfcare-portal {
  .ui.grid > .column {
    min-height: 650px;
  }
  .loading-content {
    height: 650px;
    padding: 3rem;
    margin: -1rem;
  }

  .ui.inline.loader.active {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  & .detail-item {
    display: flex;
    align-items: center;

    margin-bottom: 0.4rem;
    font-weight: @bold;
    white-space: nowrap;

    & > .ui.label {
      width: 30%;
      text-align: right;
      padding-left: 0.4rem;
      margin-right: 0.75em;
      border-right: 2px solid @nd-blue;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 0.9rem;
      font-weight: @bold;
      white-space: normal;
      flex: 0 0 auto;
    }

    & > div {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .ui.content.segment {
    display: flex;
    flex-direction: column;
  }

  & .current-subscription {
    margin-bottom: auto;
  }

  & .right-buttons {
    display: flex;
    justify-content: flex-end;
  }

  & .wide-button {
    width: 180px;
  }
}

.ui.modal.selfcare-portal-modal > .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#customer-modal,
#payment-modal,
#users-modal {
  & .ui.form {
    min-height: 500px;
  }
}

#payment-modal .payment-form {
  .field i.circular.icon {
    font-size: 0.65em !important;
  }
  .credit-card-form .inline.fields {
    justify-content: flex-end;
    margin: 0 -0.5em;

    & > .field {
      padding: 0 0.5em;

      & > .ui.dropdown {
        width: 100%;
      }
    }
  }
}

#users-modal .users-form {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .fields {
    align-items: center;
  }
  .field {
    flex-grow: 1;
  }
  .add-user {
    margin: 0 auto 1rem auto;
  }
  i.link.icon {
    font-size: 1rem;
    margin-right: 0.5em;
  }
}

#upgrade-modal {
  .ui.icon.message {
    > .icon {
      width: 4rem;
      align-self: center;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: end;

    button {
      margin-right: 0;
    }
  }

  .price {
    display: flex;
    align-items: center;
    min-height: 3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;

    label {
      display: inline-block;
      margin-left: 8px;
    }

    .value {
      font-size: 3rem;
      font-family: @header-font;
      font-weight: @header-font-weight;
    }
  }

  @media (max-width: 700px) {
    .ui.icon.message {
      flex-direction: column;
      align-items: center;
    }
    .buttons {
      align-items: center;
    }
    .price,
    .content {
      margin-top: 1rem;
    }
  }
}

#upgrade-summary-modal {
  .line {
    font-size: 0.9rem;
    text-align: right;
  }
}

.signup {
  .ui.grid > .column {
    min-height: 700px;
  }

  .invoice-data {
    font-family: @header-font;
    max-width: 50ch;
    white-space: nowrap;

    * {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .coupon-code {
    margin-top: 1rem;
    margin-bottom: 2rem;

    .ui.input {
      & > input {
        text-transform: uppercase;
      }
      & > .ui.label {
        font-weight: 500;
      }
      & > i.icon {
        width: 1.75em;
        font-size: 1.5em;

        &.check {
          color: @nd-blue;
        }
        &.delete {
          color: @red;
        }
      }
    }
  }

  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: absolute;
    top: 25%;
    left: 50%;

    width: 85% !important;
    margin: 0 !important;
    text-align: center;
    font-size: 1.14em;
    transform: translateX(-50%) translateY(-50%);
  }

  #order-summary {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    & table .description {
      font-size: 0.65rem;
      line-height: 0.65rem;
    }

    & .scroll-table {
      overflow: auto hidden;
      padding-bottom: 0.6rem;
    }
    & > .summary .line {
      font-size: 0.9rem;
      text-align: right;
    }
  }

  .buttons {
    display: none;
    margin: 0.5rem auto;
    padding: 0 4rem;

    &.active {
      display: block;
    }

    & .ui.button {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}

.smallshake.transition {
  animation-duration: 400ms;
  animation-name: smallshake;
}
@keyframes smallshake {
  0%,
  100% {
    transform: translateX(0);
  }
  25%,
  75% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
}

[data-ads="true"] .grid.product-news > .column {
  width: 50% !important;

  @media (max-width: (@largestTabletScreen)) {
    width: 100% !important;
  }
}

[data-ads="false"] .grid.product-news > .column {
  width: 50% !important;

  @media (max-width: (@largestMobileScreen)) {
    width: 100% !important;
  }
}

.product-statistics {
  font-family: @header-font;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100%;

  & > .statistics {
    display: flex;
    margin-top: 6px;
  }

  & > .ui.label {
    width: 312px;
    height: 60px;

    border-radius: 30px;
  }

  & > .statistics > .ui.label {
    width: 100px;
    height: 100px;
    margin: 0 2px;

    border-radius: 50%;
  }

  & .ui.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    flex-shrink: 0;
    flex-grow: 0;

    .label {
      font-size: 1rem;
    }

    .value {
      font-size: 1.9rem;
    }
  }
}

[data-ads="false"] .product-statistics {
  @media (min-width: @computerBreakpoint) {
    & > .ui.label {
      width: 400px;
    }
    & > .statistics > .ui.label {
      width: 120px;
      height: 120px;
      margin: 0 10px;
    }
  }
  @media (min-width: @largeMonitorBreakpoint) {
    & > .ui.label {
      width: 440px;
    }
    & > .statistics > .ui.label {
      margin: 0 20px;
    }
  }
}

.animate-badge {
  animation: badge-grow 2.25s ease-in-out;
}

@keyframes badge-grow {
  0% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(1);
  }
}

.legal {
  section {
    margin-bottom: 15px;
  }
  section {
    counter-increment: section;
  }
  h3::before {
    content: counter(section) ". ";
  }
  section p {
    counter-increment: paragraph;
  }
  section p::before {
    content: counter(section) "." counter(paragraph) " ";
  }
}

table.watches {
  button {
    margin: 5px 10px;
  }
}

table.ui.ui.ui.ui.company-representatives {
  .table-header {
    display: flex;
    align-items: center;
    gap: 1rem 0.5rem;
    flex-wrap: wrap;

    > .ui.label {
      text-transform: uppercase;
    }

    > .ui.input {
      margin-left: auto;
    }
  }

  tr td.role-hidden {
    @media (max-width: @largestMobileScreen) {
      display: none !important;
    }
    > span {
      visibility: hidden;
    }
  }
}

.ad-bottom.quartermedia-tag {
  margin-left: -15px;
  margin-right: -15px;
}

main .ui.secondary.menu {
  overflow-x: hidden;
}

.ui.top.attached.pointing.secondary.menu .item {
  margin-bottom: 0px;
  padding: 10px !important;
}

.search-select {
  overflow-x: visible !important;
}

.ui.segment.coverage {
  .ui.grid {
    // Fix spacing after data tables are initialized
    margin-top: 0;
  }

  .coverage-container {
    margin-top: 4rem;
  }

  .ui.menu .item {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }

  .filters {
    display: flex;

    > div {
      &:first-child {
        margin-left: auto;
      }
      margin-left: 1rem;
      width: 350px;
      @media (max-width: @largestMobileScreen) {
        width: 100%;
      }
    }
  }

  .ui.coverage.table {
    margin: 0.5rem 0 2rem;
  }

  .indicators.table {
    .country-list {
      display: flex;
      flex-direction: column;
      gap: 0.7rem;
    }
  }
}

.ui.table > tbody > tr > td,
.ui.table > tr > td {
  @media (min-width: @tabletBreakpoint) {
    &.centered-cell {
      text-align: center;
    }
  }
}

.ui.source.label {
  font-weight: 500;
  min-width: 40px;
  font-weight: 500;
  cursor: default;
  box-sizing: border-box;

  &.ref {
    display: inline-block;
    margin: 1px 2px;
  }
}

.ui.indicator.label {
  display: inline;
  font-weight: @bold;
}

.ui.label.inno-tag {
  &::after {
    content: none;
  }
}

div.zefix,
div.dm,
div.lei,
div.fk,
div.dp,
div.hsk,
div.chp,
div.psc,
div.ukri,
div.ukrlp,
div.roc,
div.rocar,
div.rocev,
div.scr,
div.scrar,
div.ccni,
div.ccniar,
div.bod,
div.sir,
div.inpi,
div.inpibil,
div.rne,
div.rnebs,
div.pees,
div.cvrcom,
div.cvrpc,
div.cvrreg,
div.dkcom,
div.dkper,
div.nocom,
div.noper,
div.ccrcom,
div.ccrrol,
div.secom,
div.seper,
div.epo,
div.euipotm,
div.eutr,
div.bkarta,
div.radon {
  table {
    width: 100%;
  }

  td.key {
    font-weight: bold;
    width: 25%;
  }

  td p {
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    display: block !important;
  }
}

div.rne p.warning {
  margin-top: 10px;
  font-weight: 700;
}

div.wd {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  td {
    border-style: dotted;
    border-width: thin;
    padding-left: 15px;
    padding-right: 5px;
  }

  td:not(.key) {
    word-break: break-word;
  }

  td.key {
    font-weight: bold;
    width: 25%;
  }

  td p {
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    display: block !important;
  }
  th {
    text-align: center;
    font-weight: @heavy-font-weight !important;
  }
  th.key {
    text-align: left;
  }
  td.cell {
    text-align: right;
  }
}

div.inpibil,
div.rnebs {
  th {
    text-align: center;
    font-weight: @heavy-font-weight !important;
  }
  th.key {
    text-align: left;
  }
  td.cell {
    text-align: right;
  }
}

div.dm,
div.euipotm {
  img {
    max-width: 80%;
  }
}

table.hrcd {
  border-collapse: collapse;
}

.hrcd th {
  font-weight: bold !important;
  text-align: left;
  vertical-align: top;
  padding: 0.5em !important;
  border: 1px solid black;
}

.hrcd td {
  text-align: left;
  vertical-align: top;
  padding: 0.5em !important;
  border: 1px solid black;
}

div.euipods {
  table {
    width: 100%;
  }

  td.key {
    font-weight: bold;
    width: 25%;
  }

  .image-display {
    display: block;
  }

  .image-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 7px - 7px);
    height: 400px;
    margin: 7px;
    padding: 7px;

    background-color: white;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;

    img {
      display: block !important;
      max-width: 100%;
      max-height: 100%;
      border: none;
    }
  }

  .image-panel {
    display: flex;
    flex-wrap: wrap;

    .image-wrapper {
      width: 150px;
      height: 150px;
      cursor: pointer;
      transition: none;

      &:hover,
      &:focus {
        border: 1px solid rgba(34, 36, 38, 0.35);
      }

      &.active,
      &.active:hover {
        border: 1px solid #96c8da;
      }
    }
  }
}

div.ktreg {
  pre.kk-pre {
    white-space: pre-line;
    margin-top: 0;
  }
}

table.bdl {
  td:first-child {
    min-width: em;
  }
}

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.dont-break-out() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.message.jobs-message {
  position: relative;
  background-image: url(https://welcome.northdata.com/hubfs/Jobs%20Banner%20Quentin%20og.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;

  font-size: 100% !important;
  ul {
    margin-block-start: 0 !important;
    margin-top: 0 !important;
    margin-block-end: 0 !important;
    margin-top: 0 !important;
  }

  li {
    margin-bottom: 0.3em !important;
  }

  p.close-message {
    display: inline;

    &:hover {
      opacity: 1;
    }
  }
}

.green-overlay {
  &::before {
    content: "";
    background: rgba(102, 175, 139, 0.6); // R:102 G:175 B:139
    background: linear-gradient(
      90deg,
      rgba(102, 175, 139, 0.8) 0%,
      rgba(102, 175, 139, 0.1) 100%
    );
    transition: background 0.4s ease-in-out;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;
  }

  &:hover,
  &:focus {
    &::before {
      background: rgba(102, 175, 139, 0.75);
      background: linear-gradient(
        90deg,
        rgba(102, 175, 139, 0.9) 0%,
        rgba(102, 175, 139, 0.25) 100%
      );
    }
  }

  & > * {
    position: relative;
    color: white;
    z-index: 1;
  }
}

.purple-overlay {
  &::before {
    content: "";
    background: rgba(172, 102, 175, 0.6);
    background: linear-gradient(
      90deg,
      rgba(172, 102, 175, 0.8) 0%,
      rgba(172, 102, 175, 0.1) 100%
    );
    transition: background 0.4s ease-in-out;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;
  }

  &:hover,
  &:focus {
    &::before {
      background: rgba(172, 102, 175, 0.75);
      background: linear-gradient(
        90deg,
        rgba(172, 102, 175, 0.9) 0%,
        rgba(172, 102, 175, 0.25) 100%
      );
    }
  }

  & > * {
    position: relative;
    color: white;
    z-index: 1;
  }
}

.message.dataservices-message {
  position: relative;
  background-image: url(https://welcome.northdata.com/hubfs/Data%20Services%20Banner%20Background%20Images/Data%20Services%20Banner%20Background%201.png) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;

  font-size: 100% !important;
}

.horizontalBannerDefaults {
  position: relative;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-size: cover !important;
  font-size: 100% !important;
  p.close-message {
    display: inline;

    &:hover {
      opacity: 1;
    }
  }
}

.message.newsletter-message {
  .horizontalBannerDefaults;
  background-image: url(https://welcome.northdata.com/hubfs/roman-kraft-_Zua2hyvTBk-unsplash.jpg) !important;
}

.message.faq-message {
  align-items: center !important;
  .horizontalBannerDefaults;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/banners/faq-banner.jpg) !important;
  i {
    font-family: "Josefin Sans", serif !important;
    line-height: 1.2 !important;
    font-size: 3.5em !important;
  }
  & > * {
    color: white;
  }
  p {
    margin: 0.5em 0 !important;
  }
  @media (min-width: @computerBreakpoint) {
    p {
      margin: 0.5em 8em 0.5em 0 !important;
    }
  }
}

.message.data-leader-message {
  .horizontalBannerDefaults;
  display: table !important;
  background-position: right !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/banners/data-and-ai-leaders-banner-bg.png) !important;
  i {
    display: table-cell !important;
  }
  .table.icon img {
    height: 1.5em;
    margin-right: 0.5em;
  }
  &::before {
    background: rgba(0, 156, 221, 0.9) !important;
  }
  &:hover,
  &:focus {
    &::before {
      background: rgba(0, 156, 221, 0.75) !important;
    }
  }
  p {
    margin: 0.5em 0 !important;
  }
  @media (min-width: @computerBreakpoint) {
    p {
      margin: 0.5em 8em 0.5em 0 !important;
    }
  }
  @media (max-width: @tabletBreakpoint) {
    i {
      display: inline-block !important;
      margin-bottom: 0.3em;
      height: auto;
    }
  }
}

.message.csvexport-message {
  .horizontalBannerDefaults;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/CSV-Export-banner-Background-img.jpg) !important;
  .table.icon img {
    width: 65px;
  }
}

.message.journalism-message {
  .horizontalBannerDefaults;
  background-position: right !important;
  @lightBackground: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/investigative-journalism-banner-hover.png)
    !important;
  background-image: url(https://6118348.fs1.hubspotusercontent-na1.net/hubfs/6118348/investigative-journalism-banner.png) !important;
  background-color: #000;
  color: #fff;

  .table.icon img {
    width: 65px;
  }

  // special background for touchscreen devices
  @media (any-pointer: fine) {
    &:hover {
      background-image: @lightBackground;
    }
  }

  // preload hover image
  &::after {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
    z-index: -1;
    content: @lightBackground;
  }
}

.blue-overlay {
  &::before {
    content: "";
    background: fade(@nd-blue, 60%);
    transition: background 0.4s ease-in-out;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;
  }

  &:hover,
  &:focus {
    &::before {
      background: fade(@nd-blue, 75%);
    }
  }

  & > * {
    position: relative;
    color: white;
    z-index: 1;
  }
}

sup .exposed {
  font-size: 1rem;
  margin-left: 0.3ex;
  color: @orange;
}

// shortcut classes (replacement for former inline styling)

.w-100-pc {
  width: 100% !important;
}

.mw-600-px {
  max-width: 600px !important;
}
.mw-800-px {
  max-width: 800px !important;
}

.mw-832-px {
  max-width: 832px !important;
}

.overflow-auto {
  overflow: auto !important;
}

.mt-1-em {
  margin-top: 1em !important;
}

.mb-1-em {
  margin-bottom: 1em !important;
}

.mb-1-65-em {
  margin-bottom: 1.65em !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.m-10-px-a {
  margin: 10px auto !important;
}

/* The mtb-revert-child class is used in order to correct the
 margins of div-wrapped content that is included from HubSpot */

.mtb-revert-child h3:first-child {
  margin-top: revert !important;
}

.mtb-revert-child h4:first-child,
.mtb-revert-child h4.ui.header:first-child {
  margin-top: calc(2rem - 0.142857em) !important;
}

.mtb-revert-child p:last-child,
.mtb-revert-child p:first-child {
  margin-bottom: revert !important;
}

.display-block {
  display: block !important;
}

.fs-1-rm {
  font-size: 1rem;
}

// custom elements

.feature-watches-table {
  display: block !important;
  tbody {
    width: 100%;
    display: table;
    td:last-child {
      text-align: right !important;
    }
  }
}

.video-container-large {
  width: 100%;
  max-width: 640px;
  margin-left: auto !important;
  margin-right: auto !important;
  display: block;

  img {
    height: 100%;
  }
}
.vidyard-video .consent-disclaimer {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  inset: 1rem;
  background-color: fade(@nd-white, 80%);
  border: 1px solid @nd-white;
}

// customization Semantic UI

// Reduce padding of stacked tables
@media (max-width: @largestMobileScreen) {
  .ui.ui.ui.ui.ui.table:not(.unstackable) {
    > tbody,
    > thead,
    > tfoot,
    & {
      > tr,
      > th {
        padding-top: 0.4em;
        padding-bottom: 0.4em;
      }
    }
  }
}

/* removes the top margin for links inside tooltips (e.g. _coverage page) */
.ui.popup > a {
  margin-top: 0 !important;
}
